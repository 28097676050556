$navy-blue: #2f374c; //text
$text-link: #2c39b5;
$purple-1: #4C39AA;
$grey-purple: #473065; // btn:hover
$blue-purple: #6f3bb1; // btn background
$purple-2: #8A75F5;
$light-purple: #a06be5;
$purple: purple;

$dark-grey: #20232C;
$grey-1: #EDF0FF;
$grey-2: #4C5570;
$grey-3: #8893B1;
$grey-4: #3C4356;
$grey-5: #2c3345;
$grey-6: #d5d6d8;
$text-grey: #8f8f8f;
$text-grey-1: #f7f7f7;
$grey: #d7d9e3;
$stroke: #C8C8C8;
$light-grey: #f0f2f6;
$white: #f8f8f8; // btn text:hover

$blue-sky: #289ddf;
$completed-algo: #5AB089;

$valid-color: #3A6754;
$valid-color1: #3dd88726;
$valid-color-bg: #e4f8ef;
$invalid-color: #863737;
$invalid-color1: #D06363;
$invalid-color2: #e614521c;
$invalid-color-bg: #faecec;
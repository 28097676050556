@import "../../../css/themify";

.entities-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  position: relative;

  .search-block {
    @include themify('background-color', transit-entity-bg);
    @include themify('color', primary-font-color);
    padding: 3px 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.entities-list-order-buttons {
  display: flex;
  gap: 10px;
  padding: 5px;
}
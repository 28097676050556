@import "../../../css/fonts";
@import "../../../css/themify";

.simulation-progress {
  &__inner {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .simulation-progress__state {
    @extend %font-body;
    @include themify('color', model-state-status-color);
    padding: 0 10px;
    border-radius: 2px;
  }
}
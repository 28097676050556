@import '../../css/colors.scss';
@import "../../css/themify";

.skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themify('background', skeleton-background-color);

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 30px;

    &_map {
      width: 25%;
      height: 25%;
      padding: 0;
      gap: 0;
    }

  }
}
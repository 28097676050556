.log {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 50px);

  &__controls {
    display: flex;
    align-items: center;
    gap: 10px;

    > .form-field-group.inline {
      align-items: center;
    }
  }

  &__data {
    padding: 10px 0;
    overflow: auto;
  }
}
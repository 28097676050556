@import "../../css/fonts";

.error-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-message {
    @extend %font-body;

    padding: 15px;
  }
}
@import '../../css/colors.scss';
@import "../../css/themify";

.dropdown {
  position: relative;

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .content-wrapper_outlined {
    @include themify('background', dropdown-bg);
    @include themify-border('border', dropdown-border-color);
    border-radius: 2px;
  }

  &__content {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 0 10px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__title {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__content-icon {
    display: flex;
  }

  .dropdown__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &_outlined {
      padding: 5px;
      @include themify('background', dropdown-icon-rectangle-bg);
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.dropdown-options {
  //width: var(--options-width, 216px);
  min-width: 100%;
  max-width: 600px;
  max-height: 400px;
  position: absolute;
  margin-top: 1px;
  @include themify('background', dropdown-bg);
  @include themify-border('border', dropdown-options-border-color);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: inherit;
  overflow-y: auto;
  gap: 4px;

  .dropdown-option {
    @include themify('background', dropdown-bg);
    @include themify('color', dropdown-bg);
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 12px;
    cursor: pointer;

    &:hover {
      @include themify('background', dropdown-option-hover-bg);
    }

    &__icon {
    }

    &__text {
      display: inline-block;
      flex-grow: 1;
      font-size: 12px;
      line-height: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

  }

  .section-option {
    padding: 12px 0;
    pointer-events: none;
  }
}
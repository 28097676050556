.transit-departures {
  display: flex;
  flex-grow: 1;
  gap: 10px;

  &__list {
    display: flex;
    flex-grow: 1;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}
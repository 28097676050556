@import "../../../../css/elements";
@import "../../../../css/fonts";
@import "../../../../css/themify";

$item-margin: 2px 0px;
$row-margin: 6px 6px;
$row-child-margin: 6px 24px;
$padding-x: 5px;
$padding-y: 14px;

.layers-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: $padding-y $padding-x;
  overflow-y: auto;
}

.layers-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.layers-list-header {
  @extend %font-small;
  @include themify('color', primary-font-color);
  width: 100%;
  display: inline-flex;
  margin: $item-margin;
}

.layer-row-item {
  @extend %font-body;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 2px 4px 2px 2px;

  &:hover {
    @include themify('background-color', layer-bg-hover);
  }

  &_colored {
    @include themify('background-color', selected-layer-bg);
  }

  &__controls {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.row-check,
.row-name {
  margin: $row-margin;
  height: 17px;
}

.row-name {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.row-child {
  margin: $row-child-margin;
}

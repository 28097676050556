@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// this is mostly for right sidebar
:root {
  --landscape-width: 25; // % OR vw for responsive window resizing!
  --portrait-width: 80vw;
  --header-height: 4rem;
  --secs: 250ms;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  overflow: hidden;
}

//canvas {
//  position: absolute;
//  top: 20px;
//  overflow: hidden;
//}

.viz-wrapper {
  height: 800px;
}

@import "../../../../css/themify";

.road-route-checker {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    padding: 7px 0;
  }

  &__result {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
  }

  &__distances {
    padding: 10px 0;
  }

  &__segments {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;

    .segment-card {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 5px;
      font-size: 14px;

      @include themify('background-color', route-check-segment-card-bg);
      @include themify('color', primary-font-color);
      @include themify-border('border', border-color);

      &:hover {
        opacity: 0.7;
      }

      &__line {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__properties {
        display: flex;
        gap: 5px;
      }
    }
  }

}
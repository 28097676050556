.project-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &_main {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: calc(100% - 108px);
    //position: relative;
  }
}
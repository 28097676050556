@import "../../css/elements";
@import "../../css/fonts";
@import "../../css/themify";

$padding-x: 18px;
$padding-y: 14px;

.overlay-panel-container {
  display: grid;
  max-height: calc(100vh - 110px);
  grid-template: "panel side"
  "footer side";

  .overlay-panel {
    @include themify-border('border', border-color);
    @include themify('background-color', left-panel-bg);
    @include themify('color', primary-font-color);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    grid-area: panel;
  }

  .overlay-panel-left {
    left: 0;
  }

  .overlay-panel-body {
    padding-bottom: $padding-y;
    background: inherit;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .overlay-panel-right {
    top: 25px;
    right: 75px;
  }

  .overlay-panel-footer, .overlay-panel-side {
    @include themify-border('border', border-color);
    @include themify('background-color', left-panel-bg);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-panel-footer {
    border-top: none !important;
    display: grid;
    grid-area: footer;
    bottom: 0;
  }

  .overlay-panel-side {
    display: grid;
    grid-area: side;

    svg {
      transform: rotate(90deg);
    }
  }

}

@import "../../css/themify";


.sidebar {
  width: 400px;
  z-index: 10;
  position: absolute;
  right: -400px;
  transition: transform var(--secs) linear;

  //TODO below block is temporary
  display: flex;
  flex-direction: column;
  height: calc(100% - 108px);
  justify-content: flex-start;
  align-items: center;

  @include themify('background', left-panel-bg);

  &.open {
    width: calc(1% * var(--landscape-width));
    min-width: 400px;
    transform: translateX(-400px);
  }

  &__toolbars {
    position: absolute;
    top: 10px;
    left: -40px;
    //probably this is temporary
    display: flex;
    flex-direction: column;
  }

  &__header {
    @include themify('color', primary-font-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 18px;
  }

  &__content {
    @include themify('color', primary-font-color);
    padding: 14px 18px;
    overflow: auto;
    width: 100%;
    height: 100%;
  }

}
@import "../../../css/themify";

.edit-geometry-toolbar {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 3px 10px;
  @include themify('color', primary-font-color);
  @include themify('background-color', header-bg);

  font-style: normal;
  font-size: 16px;
}
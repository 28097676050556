@import '../../css/colors';
@import '../../css/themify';

.modal {
  background-color: rgba(200, 200, 200, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-wrapper {
    width: 486px;
    max-height: 100%;
    overflow: auto;

    @include themify('background', left-panel-bg);
    @include themify-border('border', border-color);
    opacity: 100%;
    padding: 24px;

    .modal-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      position: relative;

      .content-header {
        @include themify('color', models-list-header-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
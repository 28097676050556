@import "../../css/colors.scss";

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    font-size: 14px;
    line-height: 16px;
  }

  .profile-container {
    padding: 16px;
    width: 352px;

    .groupbox-container {
      margin-bottom: 20px;

      .user-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
      }

      .subscription-description {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .rate-type {
          display: flex;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.button-block-vertical {
  margin-top: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tooltip .tooltiptext {
  font-size: 14px;
  line-height: 16px;
  visibility: hidden;
  width: 120px;
  background-color: $light-grey;
  color: $navy-blue;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

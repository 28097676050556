@import '../../../css/colors';

.icon-button {
  border: none;
  //background: rgba(47, 55, 76, 0.75);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: $navy-blue;
  }

  &.borderless {
    background: none;

    &:hover {
      background: $navy-blue;
    }
  }

  &:active, &.pressed {
    background: $blue-purple;
  }
}

.dots-icon {
  border: none;
  background: rgba(47, 55, 76, 0.75);

  &:hover {
    background: $blue-purple;
  }

  &.borderless {
    background: none;

    &:hover {
      background: $grey-purple;
    }

    &:active, &.pressed {
      background: $navy-blue;
    }
  }
}
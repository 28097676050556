@import "themify";

.header {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  height: 44px;
  z-index: 30;
  @include themify('background-color', header-bg);

  &__tab-block {
    display: flex;
    gap: 10px;
    width: 100%;
    padding-top: 2px;

    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tab-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      .tab-text {
        font-size: 14px;
        font-weight: 600;
        @include themify('color', active-tab-font-color);
      }
    }

  }

  &__right-block {
    display: flex;
    gap: 10px;
    height: 100%;
  }

  &__user-dropdown-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

@import 'themes.scss';
@import 'elements';

@mixin themify($property, $key, $themes: $themes) {
  @each $theme, $colors in $themes {
    &.#{$theme},
    .#{$theme} & {
      #{$property}: map-get($colors, $key);
    }
  }
}

@mixin themify-border($property, $key, $themes: $themes) {
  @each $theme, $colors in $themes {
    &.#{$theme},
    .#{$theme} & {
      #{$property}: $border-weight solid map-get($colors, $key);
    }
  }
}

@mixin themify-transit-entity-gradient($lightColorKey, $darkColorKey, $themes: $themes) {
  @each $theme, $colors in $themes {
    &.#{$theme},
    .#{$theme} & {
      background: linear-gradient(
                      to bottom,
                      map-get($colors, $darkColorKey),
                      map-get($colors, $darkColorKey) 30%,
                      map-get($colors, $lightColorKey) 30%,
                      map-get($colors, $lightColorKey) 70%,
                      map-get($colors, $darkColorKey) 70%
      )
    }
  }
}
@import "../../../../css/themify";

.map-filters {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  gap: 5px;
  padding: 5px 0;

  &__section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    @include themify-border('border', border-color);

    .form-field-group {
      align-items: center;
      padding: 5px 0;
    }

  }

  &__modes-section {
    max-width: 200px;
  }
}
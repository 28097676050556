@import "../../css/colors.scss";
@import "../../css/fonts.scss";
@import "../../css/elements.scss";
@import "../../css/themify";

$height-md: 35px;
$height-sm: 25px;

%button-base {
  border-width: 0.8px;
  border-style: solid;
  border-radius: 2px;
  cursor: pointer;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &[disabled],
  &:disabled {
    cursor: not-allowed;
  }

  &.borderless {
    border: none;
  }
}

%button-outline-focus {
  @include outline-focus(2px);
}

%button-outline-focus {
  outline: 2px solid $blue-sky;
}

%button-light-default {
  @extend %button-base;

  border-color: $blue-purple;
  background-color: $white;
  color: $blue-purple;
}

%button-light-focus {
  @extend %button-base;
  @extend %button-outline-focus;

  border-color: $blue-sky;
  background-color: $white;
  color: $blue-purple;
}

%button-light-disabled {
  @extend %button-base;

  border-color: $grey;
  background-color: $white;
  color: $text-grey;
}

%button-dark-default {
  @extend %button-base;

  border-color: $blue-purple;
  background-color: $blue-purple;
  color: $white;
}

%button-dark-focus {
  @extend %button-base;
  @extend %button-outline-focus;

  border-color: $blue-sky;
  background-color: $blue-purple;
  color: $white;
}

%button-dark-disabled {
  @extend %button-base;

  border-color: $stroke;
  background-color: $stroke;
  color: $white;
}

%button-hover {
  @extend %button-base;

  border-color: $blue-purple;
  background-color: $grey-purple;
  color: $white;
}

%button-active {
  @extend %button-base;

  border-color: $blue-purple;
  background-color: $navy-blue;
  color: $white;
}

.btn {
  //display: inline-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  gap: 5px;

  width: 100%;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.btn-small {
  @extend %font-small;

  height: $height-sm;
  padding: 5px 10px;
}

.btn-medium {
  @extend %font-h2;

  height: $height-md;
  width: 100%; //max-content;
  //max-width: 210px;
  padding: 8px 16px;
  line-height: 20px;
}

.btn-light {
  @extend %button-light-default;

  img {
    margin-right: 8px;
  }

  &:focus {
    @extend %button-light-focus;
  }

  &:enabled:hover {
    transition: 0.3s ease-in-out;
    @extend %button-hover;
  }

  &[disabled],
  &:disabled {
    @extend %button-light-disabled;

    span {
      color: $stroke;
    }
  }

  &:active {
    @extend %button-active;

    span {
      color: $white;
    }
  }
}

.btn-dark {
  @extend %button-dark-default;

  img {
    margin-right: 8px;
  }

  &:focus {
    @extend %button-dark-focus;
  }

  &:enabled:hover {
    transition: 0.3s ease-out;
    @extend %button-hover;
  }

  &[disabled],
  &:disabled {
    @extend %button-dark-disabled;
  }

  &:active {
    @extend %button-active;

    span {
      color: $white;
    }
  }
}

%button-transparent-default {
  @extend %button-base;

  @include themify('background', transparent-btn-bg);
  @include themify('color', transparent-btn-color);
  @include themify-border('border', transparent-btn-border-color);

  &.borderless {
    border: none;
  }
}

%button-transparent-focus {
  @extend %button-base;
  @extend %button-outline-focus;

  border-color: $blue-sky;
  background-color: inherit;
  @include themify('color', transparent-btn-color);
}

%button-transparent-disabled {
  @extend %button-base;

  border-color: $text-grey;
  background-color: inherit;
  color: $text-grey;
  cursor: default;
}

.btn-transparent {
  @extend %button-transparent-default;

  img {
    margin-right: 8px;
  }

  &:focus {
    @extend %button-transparent-focus;
  }

  &:hover {
    transition: 0.3s ease-out;
    @extend %button-hover;
  }

  &[disabled],
  &:disabled {
    @extend %button-transparent-disabled;

    &:active {
      span {
        color: inherit;
      }
    }
  }

  &:active {
    @extend %button-active;

    span {
      color: $white;
    }
  }
}

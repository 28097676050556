.sidebar-dashboards {
  height: 100%;
  display: flex;
  flex-direction: column;

  .sidebar-dashboards__controls {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .sidebar-dashboards__iframe {
    display: flex;
    flex: 1;


  }
}

.sup-set-dashboard {
  display: flex;
  flex-grow: 1;

  iframe {
    width: 100%;
  }
}
.field-with-btn {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .field-with-btn__field {
    display: flex;
    flex-grow: 1;
  }

  .field-with-btn__button-block {
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
  }
}
.accordion-summary-wrapper {
  display: flex;

  .accordion-summary-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .algorithm-details__state {
      display: flex;

      > * {
        padding: 0 10px;
        font-size: 12px;
      }
    }
  }
}

//TODO ideally this should be in styled Accordion, not in css
.MuiAccordion-root.Mui-expanded {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .MuiCollapse-root, .MuiCollapse-wrapper, .MuiCollapse-wrapperInner, .MuiAccordion-region, .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.map-wrapper {
  width: 100%;

  .mapboxgl-popup {
    .mapboxgl-popup-content {
      padding: 0 20px;

      .mapboxgl-popup-close-button {
        right: 8px;
      }
    }
  }

  .boxdraw {
    background: rgba(56, 135, 190, 0.1);
    border: 2px dashed #fe8d59;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }

  #map-top-toolbar,
  #map-bottom-toolbar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  #map-bottom-toolbar {
    bottom: 0;
  }

}

.mapboxgl-ctrl-top-right {
  display: flex;
  flex-direction: column;
  margin-top: 80px;

  .mapboxgl-ctrl {
    margin: 0 10px 0 0;

    .mapboxgl-ctrl-icon.mapboxgl-ctrl-icon_active {
      background-color: #C8C8C8;
    }
  }
}

.mapboxgl-ctrl-bottom-left {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

#info-panels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
  //top: 108px;

  //&:last-child {
  //  margin-right: 75px;
  //}
}
@import "../../css/colors";
@import "../../css/themify";


.entity-editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow: auto;

  @include themify('background-color', left-panel-bg);
  @include themify-border('border-block', border-color);
  writing-mode: horizontal-tb;

  .entity-editor__header {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;

    .title {
      display: flex;
      flex-grow: 1;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .actions {
      display: flex;
      align-items: center;
    }
  }

  .form-edit {
    .form-edit_body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      //height: 180px;
      padding: 5px 10px 10px 20px;
      border: 1px solid $stroke;
      overflow: auto;
      gap: 8px;
    }
  }
}
.geometry-form-field {
  display: flex;
  flex-grow: 1;
  gap: 10px;

  .text-field-md {
    pointer-events: none;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
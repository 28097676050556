@import "../fields";

.select-box-md {
    @extend %field-size-md;
    @extend %field-default;

    &_link {
        cursor: pointer;
        pointer-events: none;
    }

    &:focus {
        @extend %field-outline-focus;
    }

    &:active {
        @extend %field-active;
    }

    &:valid {
        @extend %field-valid;
    }

    &:invalid {
        @extend %field-invalid;
    }
}

.select-box-arrow {
    right: 0;
    position: absolute;
    padding: 4px;
}
@import "../../css/colors";

.auth-pages-wrapper {
  width: 100%;
  height: 100%;
  background: #EFEFEF;
  display: grid;
  grid-template: 36px repeat(3, minmax(0, auto)) / 1fr;
  grid-template-areas: "header"
  "logoColumn"
  "form"
  "form";

  a {
    color: $purple;
  }

  &__header {
    grid-area: header;
    padding: 10px 24px;
    background: #fff;
    display: grid; //none;
  }

  &__form-col {
    grid-area: form;
  }

  &__logo-col {
    grid-area: logoColumn;
    background: linear-gradient(139.71deg, #A3195B -56.19%, #27348B 190.21%);
    height: fit-content;
  }

  &__form-col, &__logo-col {
    display: grid;
    place-items: center;
    align-items: flex-start;
    padding: 20px;
    height: 100%;
  }

  &__logo-col {
    place-content: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 454px;
    margin-top: 24px;
  }

  &__icon {
    width: 25%;
  }
}

@media screen and (min-width: 800px) {
  .auth-pages-wrapper {
    grid-template: 36px 1fr / 1fr;
    grid-auto-rows: 1fr;
    grid-template-areas: "header header"
                          "form logoColumn";

    &__form-col, &__logo-col {
      align-items: center;
    }

    &__logo-col {
      height: 100%;
      width: 50vw;
    }

    &__icon {
      width: 100%;
    }
  }

  .logo-col-hidden {
    grid-template-areas: "header header"
                          "form form";
  }
}
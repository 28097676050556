@import "../../../css/fonts.scss";
@import "../../../css/themify.scss";

$padding-x: 24px;
$padding-y: 5px;

.map-legend-wrapper {
  @include themify('background', left-panel-bg);
  @include themify('color', primary-font-color);
  @include themify-border('border', border-color);
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  right: 50px; //12px;
  bottom: 0;
  min-width: 670px;
  height: 35px; //calc(100% - 108px);

  @media (orientation: landscape) {
    &.open {
      width: calc(1% * var(--landscape-width));
      height: max-content;
      max-height: 100%;
      transition: width var(--secs);
    }
  }

  .map-legend-wrapper__header {
    @include themify-border('border-bottom', model-item-border);

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .map-legend-wrapper__header, .map-legend-wrapper__content.legend-header {
    @extend %font-h2;
    padding: $padding-y $padding-x;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .map-legend-wrapper__content {
    overflow: auto;

    .map-legend {
      min-width: 200px;
      min-height: 110px;
      display: flex;
      flex-direction: column;
      padding: 18px 24px;

      .legend-body {
        //width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        .layer-legend {
          //width: 100%;
          display: flex;
          flex-direction: row;
          //justify-content: space-between;
          margin: 5px 0;

          .legend-column {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 0 5px;
            @extend %font-body;

            .column-header {
              display: flex;
              flex-direction: column;
              //flex-grow: 1;

              .units {
                font-size: 12px;
              }
            }

            .row {
              display: flex;
              flex-direction: row;
              align-items: center;

              .row__key {
                margin-right: 10px;
              }

              .row__value {
                margin-right: 10px;
              }
            }


            .color-legend__color {
              display: block;
              width: 16px;
              height: 16px;
              opacity: 0.7;
            }
          }
        }
      }

    }
  }
}
@import "../../css/fonts";
@import "../../css/themify";

.layers-time-selectors-wrapper {
  @include themify('color', primary-font-color);
  display: flex;
  flex-direction: row;
  max-width: 400px;
  font-size: 14px;

  .time-period-label {
    @extend %font-body-bold;
    @include themify('color', layers-time-selector-title);
  }

  .time-period {
    padding-left: 10px;
  }
}
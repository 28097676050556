@import "../../css/fonts.scss";
@import "../../css/elements.scss";
@import "../../css/colors.scss";
@import "../../css/themify";

$width: 100%;

%field-borders {
    border-width: 0.8px;
    border-style: solid;
    border-radius: 2px;
}

%field-default {
    @extend %field-borders;

    @include themify('background', search-header-input-bg);
    @include themify('color', entity-editor-field-color);
    @include themify-border('border', border-color);
}

%field-active {
    border-color: $navy-blue;
}

%field-invalid {
    border-color: $invalid-color;
    background-color: $invalid-color-bg;
    color: $invalid-color;
}

%field-valid {
  //TODO update validation colors
    //border-color: $valid-color;
    //background-color: $valid-color-bg;
    //color: $valid-color;
    @include themify('background', search-header-input-bg);
    @include themify('color', entity-editor-field-color);
    @include themify-border('border', border-color);
}

%field-outline-focus {
    @include outline-focus(1.2px);
    border-color: $blue-sky;
}

%field-size-md {
    width: $width;
    padding: $padding-md-x $padding-md-y;
    height: $height-md;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button{
    -moz-appearance: textfield;
}
input[type=number]{
    -moz-appearance: textfield;
}

//select {
//    -webkit-appearance: none;
//    -moz-appearance: none;
//    appearance: none;
//}
//
//select::-ms-expand {
//    display: none; /* remove default arrow on ie10 and ie11 */
//}
@import "../../../css/themify";

.export-button {
  @include themify('color', export-btn-text-color);
  @include themify('background', export-btn-bg);
  @include themify-border('border', dropdown-border-color);
  min-width: 118px;
  width: fit-content;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 24px 6px 19px;
  gap: 4px;
  font-size: 16px;
  border-radius: 2px;

  &:hover:not([disabled]) {
    @include themify('background', export-btn-bg-hover);

    > span {
      @include themify('color', export-btn-text-color-hover);
    }

    .icon path {
      @include themify('fill', export-btn-icon-color-hover);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}
.entities-list-controls {
  &__search {
    display: flex;
    flex-grow: 1;
    gap: 8px;
  }

  &__loupe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
  }
}
.run-to-compare-with-selector {
  display: flex;
  gap: 15px;
  align-items: center;

  .form-field-group.inline {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    height: unset;
  }
}
@import "./colors.scss";

$height-md: 32px;
$padding-md-x: 8px;
$padding-md-y: 8px;

//borders
$border-weight: 0.8px;
$border-radius: 2px;
$btn-outline-focus-weight: 2px;
$input-outline-focus-weight: 1.2px;

@mixin border-solid($color) {
    border: $border-weight solid $color;
}

@mixin outline-focus($weight) {
    outline: $weight solid $blue-sky
}
@import "../../../css/fonts";
@import "../../../css/colors";

$form-row-gap: 8px;
$form-column-gap: 8px;
$field-height-md: 50px;
$field-group-height: 70px;
$full-width: 100%;

%form-field {
  display: flex;
  position: relative;
}

.form-field-md {
  @extend %form-field;
  row-gap: 4px;
  flex-grow: 1;
  gap: 5px;
}

.form-edit {
  display: flex;
  flex-direction: column;
  row-gap: $form-row-gap;
  flex-grow: 1;
  overflow: auto;
  width: 100%;

  .form-edit_body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 8px;
  }

  .btn {
    margin-top: auto;
  }
}

.form-field-group {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 4px;

  &.inline {
    flex-direction: row;
    align-items: flex-start;

    justify-content: space-between;
  }

  &:hover {
    opacity: 0.8;
  }
}

.label-group {
  display: flex;
  gap: 5px;
  @extend %font-body-bold;
}

.form-edit-row {
  display: flex;
  align-items: center;
  column-gap: $form-column-gap;
  width: 100%;
}
@import "../css/colors.scss";
@import '../css/themify';

.modal-content {
  .nav-block {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .nav-block-header {
      @include themify('color', primary-font-color);
      display: block;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }

    .input-block {
      height: 72px;
      width: 100%;
      border: 0.8px solid $stroke;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 20px 16px;

      input {
        margin-right: 16px;
      }

      input[type="radio"]:after {
        width: 12px;
        height: 12px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 0.5px solid $text-grey;
      }

      input[type="radio"]:checked:after {
        width: 12px;
        height: 12px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $blue-purple;
        content: "";
        display: inline-block;
        visibility: visible;
      }

      input:checked {
        background-color: $blue-purple;
      }

      label {
        display: flex;
        flex-direction: column;

        .label-header {
          @include themify('color', primary-font-color);
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 2px;
        }

        .label-text {
          color: $text-grey;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .button-block-addmodel {
    margin-top: 28px;
    display: flex;
    justify-content: right;
    align-items: center;

    .disabled-button-block {
      margin-left: 8px;
    }
  }
}

.addmodel-input-block {
  @include themify('color', primary-font-color);
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  label {
    @include themify('color', primary-font-color);
    width: 30%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  input {
    margin-left: 10px;
    flex-grow: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    @include themify('color', primary-font-color);
    @include themify('background-color', search-header-input-bg);
  }
}

.check-email-page {

  &__content {
    &_bold {
      font-weight: 700;
    }
  }

  &__row {
    display: flex;
    justify-content: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    color: var(--text, #2F374C);
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__description {
    margin-bottom: 40px;
  }

  &__resend-msg {
    margin-bottom: 40px;
    white-space: pre-wrap;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
  }
}
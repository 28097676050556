@import "colors.scss";

$regular: 400;
$medium: 500;
$bold: 700;

$standard-clr: $navy-blue;
$additional-clr: $text-grey;
$link-clr: $blue-purple;

%family {
  font-family: Roboto;
}

%font-h1 {
  @extend %family;
  font-style: normal;
  font-size: 18px;
  font-weight: $bold;
  line-height: auto;
}

%font-h2 {
  @extend %family;
  font-style: normal;
  font-size: 16px;
  font-weight: $medium;
  line-height: auto;
}

%font-body-bold {
  @extend %family;
  font-style: normal;
  font-size: 14px;
  font-weight: $bold;
  line-height: auto;
}

%font-body {
  @extend %family;
  font-style: normal;
  font-size: 14px;
  font-weight: $regular;
  line-height: auto;
}

%font-small {
  @extend %family;
  font-style: normal;
  font-size: 12px;
  line-height: auto;
}
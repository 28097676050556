.tabs-nav {
    display: flex;
    list-style: none;
    .tab-link {
        width: fit-content;
    }
}
.tab-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}
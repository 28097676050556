@import "./colors.scss";
@import "./elements.scss";

/* W3C standard
   сейчас только для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $stroke $light-grey;
}
  
/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: $light-grey;
}
*::-webkit-scrollbar-thumb {
  background-color: $stroke;
  border-radius: $border-radius;
  border: $border-weight solid $light-grey;
}
@import "../../css/fonts";
@import "../../css/themify";

.breadcrumbs {
  padding: 5px 0 10px 10px;

  * {
    //@include themify('color', primary-font-color);
    color: $white; // temporary
  }

  .breadcrumbs__item {
    @extend %font-body;
    display: list-item;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
  }
}
@import "../css/colors.scss";
@import "../css/themify";

.dnd-bg {
  background-color: rgba(200, 200, 200, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 50;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .dnd-container {
    width: 70%;
    min-width: 600px;
    max-width: 800px;
    padding: 16px;
    background-color: white;
    position: absolute;

    border-radius: 5px;

    .content-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
      }
    }

    .content {
      margin-top: 16px;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top-side {
        display: flex;
        gap: 15px;

        .image {
          width: 200px;
          height: 184px;
          background-color: $light-grey;
          border: 0.8px solid $stroke;
          border-radius: 2px 2px 0px 0px;

          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 127px;
            height: 127px;
          }

          margin-bottom: 34px;
        }

        .dropzone {
          border: 2px solid $stroke;
          border-style: dashed;
          display: flex;
          flex-grow: 1;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 34px;
        }
      }

      .bottom-side {
        display: flex;
        gap: 15px;

        .left-side {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .files-list-header {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
          }

          .files-list {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .list-item-block {
              display: flex;
              justify-content: space-between;
              align-items: center;

              li {
                text-decoration: none;
                list-style-type: none;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: $navy-blue;
              }

              img {
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            }
          }
        }

        .right-side {
          display: flex;
          flex-grow: 1;
          gap: 5px;

          flex-direction: column;
          justify-content: space-between;

          .input-block {
            display: flex;
            justify-content: space-between;

            label {
              width: 30%;
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
            }

            p {
              width: 30%;
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
            }

            input {
              margin-left: 10px;
              flex-grow: 1;
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: $navy-blue;

              &:valid {
                @include themify('background-color', input-valid-color);
              }

              &:invalid {
                @include themify('background-color', input-invalid-color);
              }
            }

            select {
              width: 100%;
              flex-grow: 1;
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: $navy-blue;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .button-block {
            width: 100%;
            margin-top: 28px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}

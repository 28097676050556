.new-password-page {
  &__row {
    display: flex;
    justify-content: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    color: var(--text, #2F374C);
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__description {
    margin-bottom: 40px;
  }
}
@import "./header.scss";
@import "./searchheader.scss";
@import "./colors.scss";
@import "./fonts.scss";
@import "./scrollbars.scss";

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

body {
  > #root {
    height: 100vh;
  }
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  //color: $navy-blue;
}

* {
  font-family: Roboto;
  font-weight: 400;
}

@import "themify";

.header-search {
  width: 100%;
  height: 64px;
  @include themify('background-color', search-header-bg);
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  z-index: 20;

  &__right, &__left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 10px;
  }

  &__left {
    width: 100%;

    .search-block {
      @include themify('background-color', search-header-input-bg);
      @include themify('color', primary-font-color);
      width: 341px;
      height: 36px;
      padding: 6px;
      display: flex;

      input {
        margin-left: 8px;
        text-decoration: none;
        border: none;
        width: 100%;
        background-color: inherit;
        color: inherit;
      }

      input :active,
      :hover,
      :focus {
        outline: 0;
        outline-offset: 0;
      }
    }
  }
}

@import "../../css/fonts";
@import "../../css/themify";

.entities-list-row {
  @include themify('background-color', transit-entity-bg);
  @include themify('color', primary-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  //flex-grow: 1;

  padding: 3px 10px;
  gap: 10px;

  &_selected {
    @include themify('background-color', transit-entity-selected-bg);
  }

  &__link-wrapper, &__link {
    display: flex;
    flex-grow: 1;
  }

  &__fields {
    display: flex;
    flex-grow: 1;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .transit-entity__buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 60px;
      //height: 32px;
      flex-grow: 1;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 5px;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
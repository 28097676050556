.group-selection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;

  .description {
    font-style: italic;
    font-size: 14px;
  }
}
@import "../fields";

.text-field-md {
    @extend %field-size-md;
    @extend %field-default;

    &_link {
        cursor: pointer;
        pointer-events: none;
    }

    &:enabled:focus {
        @extend %field-outline-focus;
    }

    &:enabled:active {
        @extend %field-active;
    }

    &:valid {
        @extend %field-valid;
    }

    &:invalid {
        @extend %field-invalid;
    }

    &:disabled {
        opacity: 0.4;
    }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }
}
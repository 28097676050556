@import '../../css/colors.scss';
@import "../../css/themify";

.loading-backdrop {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 1000;

  .msg-wrapper {
    background: white;
    margin: 0 20%;
    padding: 20px;
    border-radius: 5px;
    @include themify('color', dropdown-bg);
    opacity: 70%;
    color: $navy-blue;
  }
}
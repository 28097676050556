@import '../../css/themify';

.loading-spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .MuiCircularProgress-colorSecondary {
    @include themify('color', mui-loading-spinner-color);
  }
}
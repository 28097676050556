.checkbox-field {
  display: flex;

  &_link {
    cursor: pointer;
    pointer-events: none;
  }
}

.form-field-md_align-right {
  justify-content: flex-end;
}
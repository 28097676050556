@import "../../css/colors.scss";

fieldset.groupbox-border {
  margin-bottom: 15px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 1.4em 1.4em 1.4em;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.groupbox-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0px 10px 0px 10px;
  border-bottom: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $navy-blue;
}

@import "fonts";
@import "./themify";

table {
  border-collapse: collapse;
}

.models {
  @include themify('background-color', models-list-bg);
  @include themify('color', models-text-color);
  width: 100%;
  height: 100%;
  padding: 20px 24px 8px 24px;
  overflow: auto;

  p {
    @extend %font-body;
    font-family: Roboto;
    font-style: normal;
  }

  table {
    border: none;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;

    .models-header {
      .head-text {
        @include themify('color', models-list-header-color);
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-right: 8px;
      }

      .head-left {
        display: flex;
        justify-content: left;
        padding: 15px 0 15px 0;
      }

      .head-center {
        display: flex;
        justify-content: left;
      }

      .sort-block {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;

        > * {
          margin: 0 2px;
        }
      }
    }

    tbody {
      border-spacing: 0 10px;

      tr:hover {
        @include themify('background-color', model-item-hover-bg);

        td {
          .project-row__title {
            @include themify-border('border-left', model-item-hover-border);
            @include themify-border('border-top', model-item-hover-border);
            @include themify-border('border-bottom', model-item-hover-border);
          }

          .center {
            @include themify-border('border-top', model-item-hover-border);
            @include themify-border('border-bottom', model-item-hover-border);
          }

          .right {
            @include themify-border('border-top', model-item-hover-border);
            @include themify-border('border-right', model-item-hover-border);
            @include themify-border('border-bottom', model-item-hover-border);
          }
        }
      }

      tr {
        @include themify('background-color', model-item-bg);
        cursor: pointer;

        td {
          .project-row__title {
            height: 60px;
            padding: 14px 0 14px 12px;
            display: flex;
            align-items: center;
            @include themify-border('border-left', model-item-border);
            @include themify-border('border-top', model-item-border);
            @include themify-border('border-bottom', model-item-border);
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            p {
              margin-left: 8px;
              margin-bottom: 0;
            }
          }

          .center {
            height: 60px;
            @include themify-border('border-top', model-item-border);
            @include themify-border('border-bottom', model-item-border);
            padding: 14px 12px 14px 0;
            display: flex;
            align-items: center;
            align-content: center;
            font-family: Roboto;

            .simulation-state {
              padding: 0 10px;
              border-radius: 2px;
              //width: 90px;
              text-align: center;
              //text-overflow: ellipsis;
            }
          }

          .right {
            height: 60px;
            @include themify-border('border-top', model-item-border);
            @include themify-border('border-right', model-item-border);
            @include themify-border('border-bottom', model-item-border);
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }

  .grid-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 14px;
    grid-column-gap: 16px;

    .grid-elements:hover {
      @include themify('background-color', model-item-hover-bg);
      @include themify-border('border', model-item-hover-border);
    }

    .grid-elements {
      @include themify('background-color', model-item-bg);
      @include themify-border('border', model-item-border);
      border-radius: 2px;
      width: 355px;
      height: 266px;
      padding: 16px;

      .grid-elements-content {
        display: flex;
        flex-direction: column;

        .flex-img {
          @include themify('background', model-placeholder-bg);
          @include themify-border('border', model-item-border);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 160px;
          margin-bottom: 13px;
        }

        .flex-text {
          display: flex;
          flex-direction: column;

          .grid-elements-content__header {
            @extend %font-h2;
            @include themify('color', models-text-color);
            margin-bottom: 4px;
          }

          .content-text {
            @extend %font-small;
            @include themify('color', model-grid-item-color);
            padding: 2px 0;
          }
        }
      }
    }
  }
}

.models-dropdown-content {
  .models-dropdown-text {
    @include themify('color', models-list-header-color);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}

.simulation-state {
  @extend %font-body;
  @include themify('color', model-state-status-color);
  padding: 0 10px;
  border-radius: 2px;
}

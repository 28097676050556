@import "../../css/fonts";
@import "../../css/themify";

.selection-tips-cntrls-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  gap: 10px;
  padding: 20px 50px;

  .selection-tip {
    @include themify('color', primary-font-color);
    display: flex;
    justify-content: center;

    span {
      @extend %font-body;
      background: #8A75F5;
      max-width: 700px;
      padding: 10px;
    }
  }

  .selection-save-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}